<template>
  <div class="terrain-page">
    <div class="terrain-header">
      <div class="title mr-3">
        Statistiques Terrain
      </div>
      <div
        v-if="getReportingTerrainProcessing || loading"
        class="three-dots-loading"
      >
        Chargement en cours
      </div>
    </div>
    <hr />
    <div class="terrain-container">
      <div v-if="getReportingTerrainError" class="error">
        <ul>
          <li v-for="(error, index) in getReportingTerrainError" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="grid-container" v-if="getReportingTerrain.length">
        <table class="grid">
          <template v-if="computedWeeks && computedWeeks.length">
            <colgroup>
              <template v-for="(user, index) in computedWeeks[0]">
                <col v-if="!index" :key="`c0-${index}`" style="width: 60px;" />
                <col
                  :key="`c1-${index}`"
                  :class="{ even: !(index % 2), 'col-td': true }"
                />
                <col
                  :key="`c2-${index}`"
                  :class="{ even: !(index % 2), 'col-td': true }"
                />
                <col
                  :key="`c3-${index}`"
                  :class="{ even: !(index % 2), 'col-td': true }"
                />
                <col
                  :key="`c4-${index}`"
                  :class="{ even: !(index % 2), 'col-td': true }"
                />
                <col
                  :key="`c5-${index}`"
                  :class="{ even: !(index % 2), 'col-td': true }"
                />
              </template>
            </colgroup>
          </template>
          <tr>
            <th class="empty sticky-empty top-1"></th>
            <th
              v-for="filiale in getReportingTerrain"
              :key="filiale.id"
              :colspan="filiale.colspan"
              class="b-blue sticky-top sticky-header top-1"
            >
              {{ filiale.name }}
            </th>
          </tr>
          <tr>
            <th class="empty sticky-empty top-2"></th>
            <template v-for="filiale in getReportingTerrain">
              <th
                v-for="deposit in filiale.childrens"
                :key="deposit.id"
                :colspan="deposit.colspan"
                class="b-blue-2 sticky-top sticky-header top-2"
              >
                {{ deposit.name }}
              </th>
            </template>
          </tr>
          <tr>
            <td class="empty sticky-empty top-3"></td>
            <template v-for="filiale in getReportingTerrain">
              <template v-for="deposit in filiale.childrens">
                <th
                  v-for="user in deposit.childrens"
                  :key="user.id"
                  :colspan="5"
                  class="b-blue-2 sticky-top top-3"
                >
                  {{ user.name }}
                </th>
              </template>
            </template>
          </tr>
          <tr class="rotate">
            <td class="empty sticky-empty top-4"></td>
            <template v-for="filiale in getReportingTerrain">
              <template v-for="deposit in filiale.childrens">
                <template v-for="user in deposit.childrens">
                  <th :key="'1' + user.id" class="b-blue-2 sticky-top top-4">
                    <div>PRÉ VISITES</div>
                  </th>
                  <th :key="'2' + user.id" class="b-blue-2 sticky-top top-4">
                    <div>M2 VENDUS</div>
                  </th>
                  <th :key="'3' + user.id" class="b-blue-2 sticky-top top-4">
                    <div>M2 POSÉS</div>
                  </th>
                  <th :key="'4' + user.id" class="b-blue-2 sticky-top top-4">
                    <div>POINT RATIO</div>
                  </th>
                  <th :key="'5' + user.id" class="b-blue-2 sticky-top top-4">
                    <div>KM RATIO</div>
                  </th>
                </template>
              </template>
            </template>
          </tr>
          <template v-if="computedWeeks && computedWeeks.length">
            <tr v-for="(week, index) in computedWeeks" :key="index">
              <template v-for="(user, uindex) in week">
                <th
                  :key="`w0-${index}-${uindex}`"
                  class="sticky-left"
                  v-if="user.first"
                >
                  SEM {{ user.week_nbr }}
                </th>
                <td :key="`w1-${index}-${uindex}`">
                  {{ user.nbr_visite }}
                </td>
                <td :key="`w2-${index}-${uindex}`">
                  {{ user.m_planif }}
                </td>
                <td :key="`w3-${index}-${uindex}`">
                  {{ user.m_pose }}
                </td>
                <td :key="`w4-${index}-${uindex}`">
                  {{ user.point_ratio }}
                </td>
                <td :key="`w5-${index}-${uindex}`">
                  {{ user.km_ratio }}
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    selectedWeek: {
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['getteamsfiliale', 'fetchTerrain'])
  },
  computed: {
    ...mapGetters([
      'teamsfiliale',
      'getReportingTerrain',
      'getReportingTerrainProcessing',
      'getReportingTerrainError'
    ]),
    computedWeeks: function() {
      const weekRows = []
      if (
        this.getReportingTerrain &&
        this.getReportingTerrain[0] &&
        this.getReportingTerrain[0].childrens &&
        this.getReportingTerrain[0].childrens[0] &&
        this.getReportingTerrain[0].childrens[0].childrens &&
        this.getReportingTerrain[0].childrens[0].childrens[0] &&
        this.getReportingTerrain[0].childrens[0].childrens[0].weeks
      ) {
        const weeks = this.getReportingTerrain[0].childrens[0].childrens[0].weeks.map(
          w => w.week_nbr
        )

        for (let index = 0; index < weeks.length; index++) {
          const row = []
          this.getReportingTerrain.forEach(filiale => {
            filiale.childrens.forEach(deposit => {
              deposit.childrens.forEach(user => {
                row.push({
                  id: user.id,
                  first: user.first,
                  ...user.weeks[index]
                })
              })
            })
          })
          weekRows.push(row)
        }
      }
      return weekRows
    }
  },
  async mounted() {
    this.loading = true
    await this.getteamsfiliale()
    this.fetchTerrain(this.teamsfiliale.map(team => team.id))
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.terrain-page {
  height: 100%;
  .terrain-header {
    display: flex;
    .title {
      font-size: 26px;
    }
  }

  .terrain-container {
    height: calc(100% - 33px);
    overflow-y: auto;
    overflow-x: hidden;
    .grid-container {
      display: block;
      position: relative;
      overflow: auto;
      margin-right: 2px;
      height: 100%;
      padding-bottom: 5px;
      table {
        &.grid {
          border-collapse: separate;
          table-layout: fixed;
          width: 100%;
          border-spacing: 0;
          box-sizing: border-box;
          empty-cells: show;
          outline: 0;

          .col-td {
            width: 34px;
          }
          .even {
            background-color: #f3f3f3;
          }

          tr {
            height: 34px;
            th,
            td {
              position: relative;
              text-align: center;
              outline: 0;
              vertical-align: middle;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 11px;
              white-space: nowrap;
              box-sizing: border-box;
              border-top: 1px solid #ced4da;
              border-right: 1px solid #ced4da;

              &.b-blue {
                background-color: #2dabe2;
                color: #fff;
              }

              &.b-blue-2 {
                background-color: #a6c1dc;
              }

              &.sticky-header {
                padding: 5px 20px;
                text-align: left;
                position: sticky;
                border-left: 1px solid #ced4da;
                left: 60px;
              }

              &.sticky-empty {
                position: sticky;
                left: 0;
                background: #2dabe2;
                z-index: 4;
              }

              &.sticky-left {
                background: #fff;
                position: sticky;
                left: 0;
                border-left: 1px solid #ced4da;
                z-index: 2;
              }
              &.sticky-top {
                position: sticky;
                z-index: 3;
              }

              &.top-1 {
                top: 0;
              }
              &.top-2 {
                top: 31px;
              }
              &.top-3 {
                top: 62px;
              }
              &.top-4 {
                top: 93px;
              }

              &.empty {
                border: none;
              }
            }

            th {
              padding: 5px;
            }
            td {
              padding: 5px 2px;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              th {
                &:nth-child(2) {
                  border-left: 1px solid #ced4da;
                }
              }
            }

            &.rotate {
              th {
                div {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  writing-mode: vertical-lr;
                  transform: rotate(180deg);
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
